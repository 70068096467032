var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u(
        [
          !_vm.hideImports
            ? {
                key: "tools",
                fn: function() {
                  return [
                    _vm.$vuetify.breakpoint.name == "xs" ||
                    _vm.$vuetify.breakpoint.name == "sm"
                      ? _c(
                          "span",
                          [
                            _c("emc-info-help", {
                              attrs: {
                                title: "Informações",
                                text:
                                  "Os arquivos de importação ficarão disponíveis por 18 meses!"
                              }
                            })
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "primary", small: "" } },
                              [_vm._v("mdi-information-outline")]
                            ),
                            _c("strong", { staticClass: "subtitle-1 ml-1  " }, [
                              _vm._v(
                                "Os arquivos de importação ficarão disponíveis por 18 meses!"
                              )
                            ])
                          ],
                          1
                        ),
                    _c("v-spacer"),
                    _c("sys-pop-over-import", {
                      attrs: {
                        imports: [1, 2, 5],
                        openOnHover: "",
                        title: "Junção",
                        icon: "mdi-vector-triangle"
                      },
                      on: {
                        onCompleted: function($event) {
                          return _vm.getData()
                        }
                      }
                    }),
                    _c("sys-pop-over-import", {
                      attrs: {
                        imports: [3, 68],
                        openOnHover: "",
                        title: "Horário",
                        icon: "mdi-calendar-clock"
                      },
                      on: {
                        onCompleted: function($event) {
                          return _vm.getData()
                        }
                      }
                    }),
                    _c("sys-pop-over-import", {
                      attrs: {
                        imports: [4, 69],
                        openOnHover: "",
                        title: "Grade",
                        icon: "mdi-grid-large"
                      },
                      on: {
                        onCompleted: function($event) {
                          return _vm.getData()
                        }
                      }
                    }),
                    _c("sys-pop-over-import", {
                      attrs: {
                        imports: [8, 9, 59],
                        openOnHover: "",
                        title: "Professores",
                        icon: "mdi-school-outline"
                      },
                      on: {
                        onCompleted: function($event) {
                          return _vm.getData()
                        }
                      }
                    }),
                    _c("sys-pop-over-import", {
                      attrs: {
                        imports: [6],
                        openOnHover: "",
                        title: "Demais Importações",
                        icon: "mdi-format-list-bulleted"
                      },
                      on: {
                        onCompleted: function($event) {
                          return _vm.getData()
                        }
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          "hide-edit": "",
          showDelete: false,
          showAppend: "",
          searchLength: 3
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      loading: _vm.loadingRefresh,
                                      color: "success",
                                      icon: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.getData(true)
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { btn: "" } }, [
                                  _vm._v("mdi-refresh-circle")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_c("span", [_vm._v(" Atualizar ")])]
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.import",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.import.name) + " "),
                item.description
                  ? _c("small", [_c("br"), _vm._v(_vm._s(item.description))])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.updated_at",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.updated_at_human) + " "),
                _c("br"),
                _c("small", [
                  _vm._v(_vm._s(_vm._f("dateTime")(item.updated_at)))
                ])
              ]
            }
          },
          {
            key: "item.user",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.user.name) + " "),
                item.by_integration
                  ? _c(
                      "span",
                      [
                        _c("br"),
                        _c(
                          "v-chip",
                          {
                            attrs: {
                              color: "primary",
                              label: "",
                              "x-small": "",
                              outlined: ""
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { "x-small": "" } },
                              [_vm._v("mdi-transfer")]
                            ),
                            item.operation_id
                              ? _c("span", [
                                  _vm._v(
                                    "Sistema - ID: " + _vm._s(item.operation_id)
                                  )
                                ])
                              : _c("span", [_vm._v("Sistema")])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.status.name) + " "),
                item.status.id == 2
                  ? _c(
                      "span",
                      [
                        _c("v-progress-linear", {
                          attrs: {
                            color: "deep-purple accent-4",
                            indeterminate: "",
                            rounded: "",
                            height: "3"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                [2, 3, 4].includes(item.status.id) && item.items_total
                  ? _c("small", [
                      _c("br"),
                      _vm._v(
                        "Total: " +
                          _vm._s(item.items_total) +
                          " - Erros: " +
                          _vm._s(item.items_errors) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        disabled: item.import_status_id <= 2,
                                        small: "",
                                        icon: "",
                                        href: item.url_file_name,
                                        target: "_blank"
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "success" } },
                                    [_vm._v("mdi-download")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(" Dowload Arquivo Original ")])]
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        disabled: item.import_status_id != 4,
                                        small: "",
                                        icon: "",
                                        href: item.url_error_file_name,
                                        target: "_blank"
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", color: "error" } },
                                    [_vm._v("mdi-download")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("span", [_vm._v(" Dowload Arquivo de Erros ")])]
                ),
                _vm.user.is_master
                  ? _c("emc-table-icon-edit", {
                      attrs: {
                        "tool-tip": "Reprocessar registro",
                        disabled:
                          !_vm.user.is_internal && item.import_status_id <= 3,
                        loading:
                          _vm.loadingReprocess &&
                          item.id == _vm.itemSelected.id,
                        icon: "mdi-refresh"
                      },
                      on: {
                        click: function($event) {
                          return _vm.reprocess(item)
                        }
                      }
                    })
                  : _vm._e(),
                _vm.user.is_internal || _vm.user.email == "rasantos@usjt.br"
                  ? _c("emc-table-icon-delete", {
                      attrs: {
                        disabled:
                          (item.import_status_id == 2 ||
                            _vm.user.id != item.user_id) &&
                          !_vm.user.is_internal &&
                            _vm.user.email != "rasantos@usjt.br"
                      },
                      on: {
                        click: function($event) {
                          _vm.itemDelete = item
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          },
          {
            key: "footer.prepend",
            fn: function() {
              return [
                _c("v-switch", {
                  staticClass: "ml-3",
                  attrs: {
                    inset: "",
                    label: "Exibir apenas minhas importações"
                  },
                  model: {
                    value: _vm.onlyMyImports,
                    callback: function($$v) {
                      _vm.onlyMyImports = $$v
                    },
                    expression: "onlyMyImports"
                  }
                }),
                _c("v-switch", {
                  staticClass: "ml-3",
                  attrs: { inset: "", label: "Exibir integrações de sistema" },
                  model: {
                    value: _vm.onlySystemImports,
                    callback: function($$v) {
                      _vm.onlySystemImports = $$v
                    },
                    expression: "onlySystemImports"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }